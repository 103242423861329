<template>
  <div class="container-fluid pt-5">
    <div class="row d-flex align-items-stretch h-100">
      <!-- Left Side -->
      <div class="col-md-5 ps-4 d-flex flex-column">
       <div class="mb-4">
          <h5 class="fw-bold text-blue" @click="toggleCaseInfo">
            {{ "Case Info" }}
            <span class="material-symbols-outlined float-right accordion-icon" :class="{ 'rotate-icon': isExpanded }">
              expand_less
            </span>
          </h5>
          <div v-show="isExpanded">
            <b>{{ "Case" }}</b>
            <p>{{ client_case }}</p>
            <template v-if="pre_event">
              <div class="row">
                <div class="col">
                  <b>{{ "Background/Facts of The Case" }}</b>
                  <p>{{ pre_event }}</p>
                </div>
              </div>
            </template>

            <template v-if="pre_event_file">
              <div class="row">
                <div class="col-3 d-flex flex-column align-items-start">
                  <b>{{ "Background/Facts of The Case" }}</b>
                  <button @click="openPDF" class="mt-1 btn btn-primary">
                    Open File
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
       <div class="mb-4 d-flex flex-column" style="outline: 6px solid black;">
          <div class="ps-5 p-2 flex-grow-0">  <h6 class="fw-bold text-muted">List of Prompts</h6>
            <ol>
              <li></li>
              <li></li>
            </ol>
          </div>
        </div>

        <div class="mb-4">
          <input
              class="form-control mt-2"
              type="file"
              id="formFile"
              ref="PreEventFile"
              accept=".pdf,.docx"
              @change="handleFileChange"
            />
            <small
              class="font-12 mx-1 text-center d-flex align-items-center"
            >
              <b-icon class="mx-1" icon="info-circle" />
              Please upload PDF or Document file only
            </small>
        </div>
        <div class="mb-4 flex-grow-1 d-flex flex-column" style="outline: 6px solid black;">
          <div class="ps-5 p-5">
            <h6 class="fw-bold text-muted">List of Documents Uploaded</h6>
            <ol>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ol>
          </div>
        </div>

      </div>

      <!-- Right Side -->
      <div class="col-md-7 d-flex flex-column">
        <div class="card border shadow-sm px-2 py-2 rounded mt-3 content-card flex-grow-1">
          <div class="d-flex p-3">
            <h4 class="p-3 text-blue" style="background-color: #f1f1f1; border-bottom: 2px solid #094890; border-radius: 20px 20px 0 0;">
              <b>LawBot</b>
            </h4>
          </div>

          <div class="card border-0 card_question flex-grow-1">
            <div class="chat__box" id="messages" ref="messages1">
              <!-- Incoming Chat box -->
              <div
                v-for="(message, idx) in chatMessages"
                :key="idx"
                :class="message.class_1"
              >
                <div :class="message.class_1">
                  <div :class="message.class_3">
                    <span v-html="
                      message.text
                        .replace(/\\n*/g, '<br>')
                        .replace(/\\n\\n*/g, '<br>')
                        .replace(/\\n\\n/g, '<br>')
                        .replace(/\\n/g, '<br>')
                        .replace(/\n/g, '<br>')
                        .replace(/\n\n*/g, '<br>')
                        .replace('*', '')
                        .replace(/\\/g, '<br>')
                    "></span>
                  </div>
                </div>
              </div>
              <!-- Outgoing chat box -->
              <div v-if="loadAnswer" class="chat__incoming">
                <div class="chat__bubble__wrapper-incoming">
                  <div
                    class="chat__bubble-incoming animated-background-incoming"
                  >
                    <div
                      style="transform: scale(0.7)"
                      class="circles-to-rhombuses-spinner"
                    >
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              class="chat__suggestion"
              v-for="(question, index) in default_questions.slice(0, 3)"
              :key="index"
              @click="chooseMessage(question, index)"
              :disabled="loadAnswer || question.displayed"
            >
              <span>{{ question.text }}</span>
            </button>

            <div class="chat__input">
              <input
                :disabled="loadAnswer"
                @keyup.enter="sendNewMessage"
                type="text"
                v-model="NewText"
                placeholder="Ask Questions about the Case..."
              />
              <button
                @click="sendNewMessage"
                :disabled="loadAnswer"
                class="btn-send"
              >
                <span class="material-icons-sharp">arrow_circle_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LES from "@/store/LES.js";
import Auth from "@/store/Auth.js";
import {validateInput } from "../../store/utils";
export default {
data(){
    return{
    isExpanded: false,
    client_case: "",
    client_type: "",
    jurisdiction: "",
    pre_event: "",
    pre_event_file: "",
    courtname: "",
    lawyer: "",
    judge: "",

    // chatbot
      default_questions: [
        {
          text: "List out all the legislations in the documents.",
          displayed: false,
        },
        {
          text: "Give an elaborated explanation for the legislations.",
          displayed: false,
        },
        {
          text: "Among the witnesses presented who possesses a greater weakness to the case and why?",
          displayed: false,
        },
        {
          text: "Among the witnesses presented who possesses a greater strength to the case and why?",
          displayed: false,
        },
        {
          text: "Give more explanation regarding the weakest witness.",
          displayed: false,
        },
        {
          text: "Give more explanation regarding the strongest witness.",
          displayed: false,
        },
      ],
      NewText: "",
      loadAnswer: false,
      chatMessages: [],
    }
},
created() {
    LES.GetLES(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
        this.client_case = DATA.client_case;

          this.client_type = DATA.client_type;
          this.jurisdiction = DATA.jurisdiction;
          if (this.jurisdiction.includes('"')) {
            var raw = this.jurisdiction.split('"');
            this.jurisdiction = ["USA", "United States"].includes(raw[1])
              ? "United States of America"
              : raw[1];
            this.courtname = raw[3] == "all" ? "all" : this.getCourt(raw[3]);
          }

          this.lawyer = DATA.lawyer;
          this.judge = DATA.judge_title
            ? `${DATA.judge_title} ${DATA.judge}`
            : DATA.judge;

          this.pre_event = DATA.pre_event;
          this.pre_event_file = DATA.pre_event_file;


        const OutMessage = {
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        };
        const InMessage = {
          class_1: "chat__incoming",
          class_2: "chat__bubble__wrapper-incoming",
          class_3: "chat__bubble-incoming animated-background-incoming",
          new: false,
        };
          // LawBot Code
        DATA.questions.forEach((element) => {
          if ((element.answer?.length ?? false) != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            OutMessage.text = element.question;
            this.chatMessages.push({...OutMessage});

            try {
              JSON.parse(element.answer).forEach((answer) => {
                InMessage.text = answer;
                this.chatMessages.push({...InMessage});
              });
            } catch (error) {
              InMessage.text = element.answer
              this.chatMessages.push({...InMessage});
            }
          }
        });

        if (
          DATA.questions.length != 0 &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          OutMessage.text = DATA.questions[
            DATA.questions.length - 1
          ].question;
          this.chatMessages.push(OutMessage);
        }

        if (this.chatMessages.length == 0) {
          InMessage.text = "You may ask question about the case here🔎";
          this.chatMessages.push(InMessage);
        }
        });
    },
methods:{
  toggleCaseInfo() {
      this.isExpanded = !this.isExpanded;
    },
    handleFileChange(event) {
        var result = validateInput(event.target.files[0], "file", ["pdf", "docx"]);
        if (!result[0] && result[1] !== "empty") {
          this.toastError(result[1]);
          this.pre_event_file = null;
          this.$refs.PreEventFile.value = null;
        } else {
          this.pre_event_file = result[1] === "empty"
            ? null : result[1];
        }
      },
    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;
      // // Find the next question that hasn't been displayed
      // const nextQuestion = this.default_questions
      //   .find((question) => !question.displayed);

      var obj = {
        question: message.text,
        mask: "false",
      };
      console.log("Passed into API", obj);

      LES.sendQuestion("les", this.$route.params.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },
    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "false",
        };
        this.NewText = "";

        console.log("Passed into API", obj);

        LES.sendQuestion("les", this.$route.params.id, obj)
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            this.loadAnswer = false;
            console.log(error);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },
    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;

        LES.GetLES(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3:
                      "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;

            //   if (data == "default") {
            //     me.default_questions.splice(index, 1);
            //   }
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },
  }
}
</script>
<style scoped>
.rotate-icon {
  transform: rotate(-180deg);
}
.container-fluid{
  height: 100%;
}
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
}
/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_question {
  height: 586px;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}
</style>